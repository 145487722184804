import React, {
  FC, lazy, useEffect, Suspense,
} from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { PlatformAgreementContext } from '@/context';
import onHangUp from '@/services/app';
import {
  AppHeader, HoverButton, BaseProvider, SafeModal,
} from '@/components';
import { isMobilePhone } from '@/utils/helper/base';
import configs from '@/configs';
import '@/assets/styles/introJs.less';

const { Content } = Layout;
const Home = lazy(() => import('./pages/home'));
const Admin = lazy(() => import('./pages/admin'));
const Exception = lazy(() => import('./pages/exception'));
const SingleProduct = lazy(() => import('./pages/single-product'));
const Letter = lazy(() => import('./pages/letter'));
const MailReport = lazy(() => import('./pages/mail-report'));
const mobileServer = configs('app.mobileServer');

const App: FC = () => {
  useEffect(() => {
    if (isMobilePhone()) {
      if (window.location.pathname?.includes('/single-product/report-detail')) {
        window.location.href = `${mobileServer}/report/detail${window.location.search}`;
      }
      if (window.location.pathname?.includes('/report-library/detail/')) {
        const strList = window.location.pathname.split('/');
        if (strList?.length === 4) {
          window.location.href = `${mobileServer}/report/detail?openid=${strList[3]}`;
        }
      }
      if (window.location.pathname?.includes('/essence-monthly-report/')) {
        const strList = window.location.pathname.split('/');
        if (strList?.length === 3) {
          window.location.href = `${mobileServer}/essence-monthly-report/${strList[2]}`;
        }
      }
      if (window.location.pathname?.includes('/cultural-signals/detail')) {
        const strList = window.location.pathname.split('/');
        if (strList?.length === 4) {
          window.location.href = `${mobileServer}/cultural-signals/detail/${strList[3]}`;
        }
      }
    }
    const {
      protocol, search, pathname, hostname,
    } = window.location;
    if (process.env.REACT_APP_ENV !== 'local' && protocol === 'http:') {
      window.location.href = `https://${hostname}${pathname}${search}`;
    }
    onHangUp();
  }, []);

  return (
    <BaseProvider>
      <SafeModal />
      <Switch>
        <Route path="/admin">
          <AppHeader.AdminHeader />
          <Content className="mur-layout">
            <Suspense fallback={<div />}>
              <Admin />
            </Suspense>
          </Content>
        </Route>
        <Route path="/single-product">
          {/* <HoverButton.Suggest /> */}
          <HoverButton.MobileQRCode />
          <Suspense fallback={<div />}>
            <SingleProduct />
          </Suspense>
        </Route>
        <Route path="/exception">
          <HoverButton.Suggest />
          <AppHeader.HomeHeader />
          <HoverButton.MobileQRCode />
          <Content className="mur-layout">
            <Suspense fallback={<div />}>
              <Exception />
            </Suspense>
          </Content>
        </Route>
        <Route path="/letter">
          <Suspense fallback={<div />}>
            <Letter />
          </Suspense>
        </Route>
        <Route path="/mail-report">
          <Suspense fallback={<div />}>
            <MailReport />
          </Suspense>
        </Route>
        <Route path="/">
          <PlatformAgreementContext.Provider>
            {/* <HoverButton.Suggest /> */}
            <HoverButton.MobileQRCode />
            <Content>
              <Suspense fallback={<div />}>
                <Home />
              </Suspense>
            </Content>
          </PlatformAgreementContext.Provider>
        </Route>
      </Switch>
    </BaseProvider>
  );
};

export default App;
