/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, {
  FC, useEffect, useState,
  createRef, useCallback, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import {
  Alert, Button, message, Modal,
} from 'antd';
import { PhotoSlider } from 'react-photo-view';
import { ReportPreviewData, getReportImages } from '@/services/report-library';
import { getZoneReportImages } from '@/services/single-product/home';
// import AICataLog from '@/pages/home/report-library/components/ai-catalogue';
import { throttle } from 'lodash';
import styles from '../index.module.less';
import 'react-photo-view/dist/index.css';

interface Props {
  data: ReportPreviewData,
  anchor: number,
  productId?: number,
  openid: string|number,
  trigger?: boolean, // 跳转触发器，为了点击到相同的索引也可跳转
  toJumpIdx?: (idx: number) => void, // 滚动触发对应目录展开
}

interface DataSource {
  images: string,
  id: number,
}

const FullReport: FC<Props> = ({
  data, productId, anchor, trigger, toJumpIdx,
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initPageSize = 5;
  const initDataSource = data.preview_pic
    ? data.preview_pic.map((item, index) => ({ images: item, id: index * -1 }))
    : [];
  const loadingRef = createRef<HTMLDivElement>();
  const refSort = useRef<number>(0); // 记录预览图片的数量最新状态
  // const refSortInit = useRef<boolean>(false); // 是否要触发加载动画
  const refPage = useRef<number>(initDataSource.length); // 记录当前page的最新状态

  const [sort, setSort] = useState<number>(Number(searchParams.get('sort') || '0'));
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [tipsVisible, setTipsVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DataSource[]>(searchParams.get('sort') ? [] : [...initDataSource]);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [previewImages, setPreviewImages] = useState<string[]>(data.preview_pic || []);
  const [loadMore, setLoadMore] = useState<boolean>(!!sort);
  const [sortLoading, setSortLoading] = useState<boolean>(!!sort);
  const [sortPercent, setSortPercent] = useState<string>('0%');
  // const [initSortImg, setInitSortImg] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [hasClickAnchor, setHasClickAnchor] = useState(false); // 点击目录跳转后的滚动 开关
  const stopScroll = useRef<boolean>(false);

  const getDataSource = async (id: string, pageNo: number, pageSize: number) => {
    setLoading(true);
    let res;
    if (productId) {
      res = await getZoneReportImages(productId, id, pageNo, pageSize);
    } else {
      res = await getReportImages(id, pageNo, pageSize);
    }
    if (res?.length) {
      setLoading(false);
      let list;
      if (pageNo === 1) {
        list = res;
      } else {
        list = [...dataSource, ...res];
      }
      setDataSource(list);
      setPreviewImages(list.map((item) => item.images || ''));
    } else {
      setHasMore(false);
    }
  };

  const handleDataSource = async (paramPageNo: number, paramPageSize: number) => {
    await getDataSource(data?.openid as string, paramPageNo, paramPageSize);
  };

  const handlePreviewImageChange = async (index: number) => {
    if (dataSource.length - 1 === index) {
      if (hasMore) {
        setPage(page + 1);
      }
      if (!loadMore) {
        setLoadMore(true);
      }
    }
    if (!previewImages[index] && dataSource) {
      previewImages[index] = dataSource[index].images;
      setPreviewImages(previewImages);
    }
    setPreviewIndex(index);
  };

  const handleImgClick = (item: string, index: number) => {
    previewImages[index] = item;
    setPreviewImages(previewImages);
    setPreviewIndex(index);
    setVisible(true);
  };

  // 点击目录跳转后打开图片预览
  // const showAnchorImg = (index: number) => {
  //   console.log(index);
  //   setTimeout(() => {
  //     const toIndex = Math.abs(index - 1); // 可能锚点为0 直接取绝对值
  //     previewImages[index] = dataSource[toIndex].images;
  //     setPreviewImages(previewImages);
  //     setPreviewIndex(index);
  //     setVisible(true);
  //   }, 300);
  // };

  // 创建 Intersection Observer
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      // 图片已滚动到视图中
      if (entry.isIntersecting) {
        // 等待锚点元素出现后，禁止滚动结束
        stopScroll.current = false;
        // showAnchorImg(anchor);
        // 停止观察
        observer.unobserve(entry.target);
      }
    });
  }, {
    threshold: 0.7,
  });

  const getVisiblePercentage = (el) => {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    // 计算元素在视口内的可见区域
    const visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
    const visibleWidth = Math.min(rect.right, windowWidth) - Math.max(rect.left, 0);

    // 如果元素完全不可见，返回 0
    if (visibleHeight <= 0 || visibleWidth <= 0) {
      return 0;
    }

    // 计算元素的总面积
    const totalArea = rect.width * rect.height;

    // 计算可见区域的面积
    const visibleArea = visibleHeight * visibleWidth;

    // 返回可见区域的比例
    return (visibleArea / totalArea) * 100;
  };

  // 设置目录跟随滚动打开 （仅适用于研究报告详情的目录）
  const setScroll = throttle(() => {
    if (!toJumpIdx) return; // 上层组件没有传递该函数值则不触发
    if (stopScroll.current) return;
    // 获取滚动容器
    const scrollContainer = document.getElementById('previewBox');

    // 获取所有需要观察的元素
    const items = scrollContainer?.querySelectorAll('img');
    if (items) {
      items.forEach((item) => {
        const rect = item.getBoundingClientRect();
        const index = item.id.slice(8);
        if (rect.height >= (window.innerHeight || document.documentElement.clientHeight)) {
          // 图片高度超出视图，则判断出现在视图的面积的百分比
          if (getVisiblePercentage(item) > 75) {
            toJumpIdx(Number(index));
          }
        } else {
          // 否则判断元素是否完全出现在视图中
          // eslint-disable-next-line no-lonely-if
          if (rect.top >= 0
            && rect.left >= 0
            && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            && rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
            toJumpIdx(Number(index));
          }
        }
      });
    }
  }, 400);

  const handleLoadMoreClick = async () => {
    setPage(page + 1);
    setLoadMore(true);
  };

  // 跳转到锚点   如果首次触发锚点跳转已触发，再点击相同的anchor 则直接跳转到对应img
  const scrollToAnchorImg = () => {
    const img = document.getElementById(`preview-${anchor}`);
    if (img) {
      img.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      observer.observe(img);
    }
  };

  // 加载图片
  const loadImage = () => {
    refSort.current += 1;
    setSortPercent(`${((refSort.current / Number(dataSource.length)) * 100).toFixed(0)}%...`);
    // 当所有图片加载后完成后才进行锚点跳转
    if (refSort.current === dataSource.length) {
      const img = document.getElementById(`preview-${sort}`);
      if (img && hasClickAnchor) {
        img.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        observer.observe(img);
      }
      setSortPercent('100%');
      setHasClickAnchor(false); // 关闭开关
      setSortLoading(false);
    }
  };

  // 请求预览图片的page 滚动 或者 点击加载更多才触发 （点击目录跳转不能触发）
  useEffect(() => {
    if (!hasClickAnchor && page > 1) {
      handleDataSource(page, initPageSize);
    }
  }, [page]);

  // 滚动加载 记录最新的page
  useEffect(() => {
    if (!sortLoading) {
      refPage.current = page;
    }
  }, [dataSource]);

  // 父级传递下来的 锚点跳转参数
  useEffect(() => {
    if (anchor) {
      if (anchor > 5) {
        setLoadMore(true);
      }
      setSort(anchor);
      stopScroll.current = true;
      // 跳转的锚点，不在当前的预览图片中，则要重新请求
      if (anchor > dataSource.length) {
        setHasClickAnchor(true); // 开关
        setSortPercent('0%'); // 设置定位的目录进度
        setSortLoading(true); // 开启定位进度弹窗
        refSort.current = 0; // 触发了请求 则重置图片数量状态为0
        // 如果传进有锚点，则直接请求锚点页之前的所有img
        handleDataSource(1, (Number((anchor / 10).toFixed(0)) + 1) * 10);
        // 重置page
        setPage(((Number((anchor / 10).toFixed(0)) + 1) * 10) / 5);
      }
      scrollToAnchorImg();
    }
  }, [anchor, trigger]);

  const scrollFn = useCallback(() => {
    setScroll();
    const onPullUpHeight = loadingRef!.current?.clientHeight || 0;
    const documentHeight = document.documentElement.clientHeight;
    const documentTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (onPullUpHeight > documentHeight
      && onPullUpHeight < documentHeight + documentTop - 150
      && !loading && loadMore && hasMore
    ) {
      setPage(page + 1);
    }
  }, [loading, loadingRef]);

  useEffect(() => {
    window.addEventListener('scroll', scrollFn);
    return () => {
      window.removeEventListener('scroll', scrollFn);
    };
  }, [scrollFn]);

  const keydownFn = useCallback((e) => {
    setScroll();
    if (!hasMore && e.keyCode === 39
      && !tipsVisible && previewIndex === dataSource.length - 1) {
      message.warn('已经到底啦~');
      setTipsVisible(true);
    }
  }, [hasMore, tipsVisible, previewIndex, dataSource]);

  useEffect(() => {
    window.addEventListener('keydown', keydownFn);
    return () => {
      window.removeEventListener('keydown', keydownFn);
    };
  }, [scrollFn]);
  return (
    <div ref={loadingRef}>
      {!data.preview_pic && (
        <Alert
          message={t('tips')}
          description={t('wait_generating_tips')}
          type="info"
          showIcon
        />
      )}
      {data?.preview_pic && (
        <section id="previewBox" className={`${styles.section} ${styles.previewSection}`}>
          {dataSource.map(((item, index) => (
            <img
              key={`${item.id}img`}
              className={styles.previewImg}
              src={item.images}
              alt=""
              id={`preview-${index + 1}`}
              onClick={() => handleImgClick(item.images, index)}
              onLoad={() => {
                loadImage();
              }}
            />
          )))}
          <PhotoSlider
            images={previewImages.map((item) => ({ src: item }))}
            visible={visible}
            onClose={() => {
              setVisible(false);
              setTipsVisible(false);
            }}
            index={previewIndex}
            onIndexChange={(index: number) => handlePreviewImageChange(index)}
            imageClassName={styles.previewPhoto}
            maskClassName={styles.mask}
            // overlayRender={() => <AICataLog id={data.openid} />}
          />
          {!loadMore && data.read_auth === 1 && (
            <Button
              className={styles.loadingMore}
              onClick={handleLoadMoreClick}
            >
              <div>
                <span>{t('view_full_report')}</span>
                <DownOutlined style={{ fontSize: 10, marginLeft: 6 }} />
              </div>
              <div className={styles.loadingMoreTips}>
                （禁止copy及大范围传播）
              </div>
            </Button>
          )}
        </section>
      )}
      <Modal
        visible={sortLoading}
        footer={false}
        maskClosable={false}
        mask={false}
        closable={false}
        centered
        width={300}
        bodyStyle={{
          padding: '24px 52px',
          height: '152px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        wrapClassName={styles.sortLoad}
      >
        <p className={styles.sortLoadTitle} />
        <p className={styles.sortLoadText}>
          {sortPercent}
        </p>
        <p
          className={styles.sortLoadText}
          style={{ opacity: 0.65 }}
        >
          正加载到原文位置中，请稍等
        </p>
      </Modal>
    </div>
  );
};

export default FullReport;
